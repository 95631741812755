<script>

//import VuePreloader from 'vue2-preloader';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
const moment = require('moment');
import Swal from 'sweetalert2';
import { ref } from 'vue'
import debounce from "lodash.debounce";









//const moment = require('moment');

/**
 * Invoice-list component
 */
export default {
  page: {
    title: "Invoice Pelanggan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Loading,
    DatePicker


    //VuePreloader

  },
  data() {
    return {
      title: "Invoice Pelanggan",
      items: [
        {
          text: "Invoices",
        },
        {
          text: "Invoice Pelanggan",
          active: true,
        },
      ],

      invoicelist: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      compoAcordion: {
        acordion1: false,
        acordion2: false,
        acordion3: false,
        acordion4: false
      },
      stickyHeader:true,
      sortDesc: false,
      fields: [

        {
          key: "orderId",
          label: "Order Id",
          text: "Order ID",
          value: "orderId",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "status",
          label: "Status",
          text: "Status",
          sortable: true,
          value: "status",
          thStyle: { width: "3%" },
        },
        {
          key: "nama",
          label: "Nama Pelanggan",
          text: "Nama Pelanggan",
          value: "nama",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "products",
          label: "Paket",
          text: "Paket",
          value: "products",
          sortable: true,
          thStyle: { width: "18%" },
          formatter: (value) => {
            if (value.length <= 35) {
              return value.toUpperCase()
            } else {
              return (value.substring(0, 35) + '...').toUpperCase()
            }
          },
        },
        {
          key: "jumlahTagihan",
          label: "Tagihan",
          text: "Tagihan",
          thStyle: { width: "10%" },
          value: "jumlahTagihan",
          //sortable: true,
          formatter: (value) => {
            var formatter = new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR'
            });
            return formatter.format(value);
          },
        },
        {
          key: "periodeInvoice",
          label: "Periode",
          text: "Periode",
          value: "periodeInvoice",
          //   sortable: true,
          thStyle: { width: "11%" },
        },
        {
          key: "masaTenggang",
          label: "Batas Pembayaran",
          text: "Batas Pembayaran",
          thStyle: { width: "13%" },
          value: "masaTenggang"
          //  sortable: true,
        },

      ],

      costumeFields: [
        { text: 'Order ID', value: 'orderId', key: "orderId", label: 'Order ID', sortable: true, thStyle: { width: "13%" } },
        { text: 'Nomor Faktur', value: 'codetagihan', key: "codetagihan", label: "Nomor Faktur", sortable: true, thStyle: { width: "13%" } },
        { text: 'Client Grup', value: 'clientGrup', key: "clientGrup", label: "Client Grup", thStyle: { width: "12%" } },
        { text: 'Status', value: 'status', key: "status", label: "Status", sortable: true, thStyle: { width: "3%" } },
        { text: 'Nama Pelanggan', value: 'nama', key: "nama", label: "Nama Pelanggan", sortable: true, thStyle: { width: "15%" } },
        {
          text: 'Paket,', value: 'products', key: "products", label: "Paket", sortable: true, thStyle: { width: "18%" },
          formatter: (value) => {
            if (value.length <= 35) {
              return value.toUpperCase()
            } else {
              return (value.substring(0, 35) + '...').toUpperCase()
            }
          }
        },
        {
          text: 'Tagihan', value: 'jumlahTagihan', key: "jumlahTagihan", label: "Tagihan", thStyle: { width: "10%" }, sortable: true,
          formatter: (value) => {
            var formatter = new Intl.NumberFormat('id-ID', {
              style: 'currency',
              currency: 'IDR'
            });
            return formatter.format(value);
          }
        },
        { text: 'Periode', value: 'periodeInvoice', key: "periodeInvoice", label: "Periode", thStyle: { width: "11%" } },
        { text: 'Batas Pembayaran', value: 'masaTenggang', key: "masaTenggang", label: "Batas Pembayaran", thStyle: { width: "13%" } },

      ],

      showAnimation: false,
      isLoading: false,
      fullPage: true,
      selected: [],
      checkBoxClientGrup: [],
      value2: moment().format('DD-MM-YYYY'),
      // paginationCountMin: 0,
      paginationCountSkip: 0,

      leftFilterData: {
        periodeAwal: null,
        periodeAkhir: null,
        clientGrup: [],
        statusInvocies: [],
        rentangHargaAwal: null,
        rentangHargaAkhir: null,
        tanggalTagihan: null,
        tanggalTransaksi: null,
      },
      holderRentangHargaAwal: '',
      rentangHargaAkhir: 0,
      statusInvoiceCekBox: [
        'Terbayar', 'Belum Terbayar', 'Dibatalkan'
      ],
      statusInvoiceSelected: [],
      limit: 50,
      skip: 0,
      advFilteron: false,
      countpushMax: 50,
      initialData: false,
      initialQuery: 0,
      queryCheck: "",
      advFilterDataFound: 0,
      dynamicSearchState: false,
      dynamicSearchText: "",
      dynamicSearchPagination: false,
      costumeFilterTableLimit: false,
      loadingRegexQue: false,
      valueDebounce: "",
      checkedNames: ref(['orderId', 'status', 'nama', 'products', 'jumlahTagihan', 'periodeInvoice', 'masaTenggang']),
      previousSelectedStructures: ['orderId', 'status', 'nama', 'products', 'jumlahTagihan', 'periodeInvoice', 'masaTenggang'],

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.invoicelist.length;
    },

  },
  async created() {
    // Set the initial number of items
    this.open()

    this.totalRows = this.items.length

    this.debouncedWatch = await debounce(async (newValue) => {
      await this.dynamicSearchTextMethod(newValue)
      // this.valueDebounce= newValue

    }, 300);


  },

  beforeUnmount() {
    this.debouncedHandler.cancel();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClicked(data) {

      this.$router.push('/customer/' + data.customerData)
    },
    async skipCount() {

      this.paginationCountSkip = 0

      this.paginationCountSkip = (this.currentPage) * this.perPage + 20

      return this.paginationCountSkip
    },

    async checkPagination() {

      if (this.advFilteron) {

        await this.applyFilter()
      }
      else if (!this.advFilteron) {

        await this.open()
      }
    },
    async resetAdvFilter() {
      this.leftFilterData = {
        periodeAwal: null,
        periodeAkhir: null,
        clientGrup: [],
        statusInvocies: [],
        rentangHargaAwal: null,
        rentangHargaAkhir: null,
        tanggalTagihan: null,
        tanggalTransaksi: null,
      }
      this.invoicelist = []
      this.dynamicSearchText = ""

      this.compoAcordion.acordion1 = false
      this.compoAcordion.acordion2 = false
      this.compoAcordion.acordion3 = false
      this.compoAcordion.acordion4 = false

      window.scrollTo(0, 0)

      await this.open()


    },

    async rentangHarga() {
      let a = parseInt(this.leftFilterData.rentangHargaAkhir)
      let b = parseInt(this.leftFilterData.rentangHargaAwal)
      if (a <= b && this.leftFilterData.rentangHargaAwal != null && this.leftFilterData.rentangHargaAkhir != null) {

        let timerInterval
        Swal.fire({
          title: 'Peringatan!',
          html: 'Harga Maksimal Tidak Boleh Kurang Dari Harga Minimal ',
          timer: 4000,
          timerProgressBar: true,
          showCloseButton: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(timerInterval)
          },

        }).then((result) => {
          /* Read more about handling dismissals below */

          if (result.dismiss === Swal.DismissReason.timer) {

            this.leftFilterData.rentangHargaAwal = null
            this.leftFilterData.rentangHargaAkhir = null

          }

          this.leftFilterData.rentangHargaAwal = null
          this.leftFilterData.rentangHargaAkhir = null
          return true
        })
      }
      else {

        return false
      }

    },


    async getInvoiceList() {

      this.advFilteron = false

      var skipData = await this.skipCount()
      let query = ""
      if (skipData == 30) {
        skipData = 0
      }

      if (this.dynamicSearchState == true) {
        await this.$nextTick().then(() => {
          let forUn
          for (var x = 0; x < 5000; x++) {
            forUn = x
          }

          query = query + '&$regex=' + this.dynamicSearchText
          this.dynamicSearchPagination == true
          return forUn
        });

      }


      if (this.countpushMax <= skipData || this.invoicelist.length == 0) {


        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice?invoicesTable=true&$skip=' + skipData + query).then(response => {

          if (this.countpushMax <= skipData) {
            this.countpushMax = this.countpushMax + 50

            this.invoicelist = this.invoicelist.concat(response.data.invoices)

          }
          else if (this.invoicelist.length == 0) {
            this.invoicelist = response.data.invoices

          }
          this.checkBoxClientGrup = []
          for (let index = 0; index < response.data.clientGrup.length; index++) {
            this.checkBoxClientGrup.push(response.data.clientGrup[index].code)

          }

        });
      }
      this.advFilteron = false
      this.loadingRegexQue = false

    },

    async setAdvFilter() {
      this.initialData = false
      this.dynamicSearchText = ""
      this.advFilterDataFound = 0
      this.paginationCountSkip = 0
      this.currentPage = 1
      await this.applyFilter()
    },

    async open() {

      let loader = this.$loading.show({
        loader: 'spinner',
        color: '#ff5024'
      });

      await this.getInvoiceList()

      loader.hide()

    },

    setTbodyTrClass(item) {
      const classes = [];
      if (item.active) classes.push('custom-border');
      return classes;
    },

    async applyFilter() {
    
      if (this.advFilterDataFound <= 50 && this.initialData == true && this.dynamicSearchState == false) {

        return "oke"
      }

      this.advFilteron = true
      let query = ""



      if (this.leftFilterData.clientGrup.length != 0) {
        for (let index = 0; index < this.leftFilterData.clientGrup.length; index++) {
          query = query + "&clientGroup[$in][]=" + this.leftFilterData.clientGrup[index]

        }
      }
      if (this.leftFilterData.statusInvocies.length != 0) {

        for (let index = 0; index < this.leftFilterData.statusInvocies.length; index++) {
          let status = ""
          if (this.leftFilterData.statusInvocies[index] == "Terbayar") {
            status = "settlement"
          }
          if (this.leftFilterData.statusInvocies[index] == "Belum Terbayar") {
            status = "pending"
          }
          if (this.leftFilterData.statusInvocies[index] == "Dibatalkan") {
            status = "cancel"
          }
          query = query + "&status[$in][]=" + status
        }

      }

      //periode

      if (this.leftFilterData.periodeAwal != null && this.leftFilterData.periodeAkhir == null) {

        let formaterPeriodeAwal = moment(this.leftFilterData.periodeAwal).subtract(1, "months").format('YYYYMM-YYYYMM')
        let cekCurrentMonth = moment().format('YYYYMM-YYYYMM')

        if (formaterPeriodeAwal == cekCurrentMonth && this.leftFilterData.periodeAkhir == null) {
          query = query + "&invoicePeriode=" + formaterPeriodeAwal
        }
        else {
          query = query + "&invoicePeriode[$gt]=" + formaterPeriodeAwal
        }


      }
      if (this.leftFilterData.tanggalTransaksi != null) {
        query = query + "&paidAt[$gt]=" + moment(this.leftFilterData.tanggalTransaksi).subtract(1, "days").format("MM-DD-YYYY") + "&paidAt[$lt]=" + moment(this.leftFilterData.tanggalTransaksi).add(1, "days").format("MM-DD-YYYY")
      }

      if (this.leftFilterData.tanggalTagihan != null) {
        query = query + "&createdAt[$gt]=" + moment(this.leftFilterData.tanggalTagihan).subtract(1, "days").format("MM-DD-YYYY") + "&createdAt[$lt]=" + moment(this.leftFilterData.tanggalTagihan).add(1, "days").format("MM-DD-YYYY")
      }

      if (this.leftFilterData.periodeAkhir != null && this.leftFilterData.periodeAwal != null) {
        let formatPerAwal = moment(this.leftFilterData.periodeAwal).subtract(1, "months").format('YYYYMM-YYYYMM')
        let formatPerAkhir = moment(this.leftFilterData.periodeAkhir).add(1, "months").format('YYYYMM-YYYYMM')
        query = query + "&invoicePeriode[$gt]=" + formatPerAwal + "&invoicePeriode[$lt]=" + formatPerAkhir
      }

      if (this.leftFilterData.periodeAkhir != null && this.leftFilterData.periodeAwal == null) {

        let formatPerAkhir = moment(this.leftFilterData.periodeAkhir).add(1, "months").format('YYYYMM-YYYYMM')
        query = query + "&invoicePeriode[$lt]=" + formatPerAkhir
      }
      //end of periode
      if (this.leftFilterData.rentangHargaAwal != null && this.leftFilterData.rentangHargaAkhir == null) {
        query = query + "&grossAmount[$gt]=" + this.leftFilterData.rentangHargaAwal
      }

      if (this.leftFilterData.rentangHargaAwal == null && this.leftFilterData.rentangHargaAkhir != null) {
        query = query + "&grossAmount[$lt]=" + this.leftFilterData.rentangHargaAkhir
      }
      if (this.leftFilterData.rentangHargaAwal != null && this.leftFilterData.rentangHargaAkhir != null) {
        query = query + "&grossAmount[$lt]=" + this.leftFilterData.rentangHargaAkhir + "&grossAmount[$gt]=" + this.leftFilterData.rentangHargaAwal
      }

      var skipData = await this.skipCount()

      if (skipData == 30) {
        skipData = 0
      }

      if (this.advFilterDataFound < skipData && this.initialData == true) {

        return "oke"
      }


      if (this.dynamicSearchPagination == true) {
        query = query + "&$regex=" + this.dynamicSearchText
      }

      if (this.dynamicSearchState == true && this.advFilteron == true && this.initialData == true && this.dynamicSearchPagination == false) {

        this.initialData = false
        query = query + "&$regex=" + this.dynamicSearchText
        this.dynamicSearchPagination = true
      }


      if (this.countpushMax <= skipData || this.initialData == false) {

        query = query + "&$skip=" + skipData
        let loader = this.$loading.show({
          loader: 'spinner',
          color: '#ff5024'
        });


        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice?advFilter=true' + query).then(async (response) => {
         
          if (this.countpushMax <= skipData) {
            this.invoicelist = this.invoicelist.concat(response.data.invoices)
            this.countpushMax = this.countpushMax + 50

          }
          else if (this.initialData == false) {
            this.invoicelist = response.data.invoices
            this.advFilterDataFound = response.data.dataFound
            this.initialData = true
          }

        });
       
        loader.hide()
        window.scrollTo(0, 0)
        this.loadingRegexQue = false

      }


      this.advFilteron = true
    },
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today
    },
    async tableCostume(target) {
      
      if (target.do == "hapus") {
        this.fields.splice(target.index, 1)
       
      }
      else if (target.do == "tambah") {
        this.fields.splice(target.index, 0, this.costumeFields[target.index])
       
      }

    },
    
    async dynamicSearchTextMethod(filter) {

      if (filter.length >= 3)
        if (!this.loadingRegexQue) {
          this.loadingRegexQue = true
          setTimeout(async () => {
            this.currentPage = 1
            this.dynamicSearchPagination = false
            this.countpushMax = 50
            if (filter.length >= 3) {
              this.dynamicSearchState = true

              if (this.advFilteron) {
                await this.applyFilter()
              }
              else if (!this.advFilteron) {
                this.invoicelist = []
                await this.open()
              }


            }
            else if (filter.length <= 3 && filter.length == 0) {
              this.dynamicSearchState = false
              this.dynamicSearchPagination = false

              if (this.advFilteron) {
                await this.applyFilter()
              }
              else if (!this.advFilteron) {
                await this.open()
              }
            }

          }, 200)

        }



    },





  },
  watch: {

    'leftFilterData.periodeAkhir': {
      handler() {

        if (this.leftFilterData.periodeAkhir < this.leftFilterData.periodeAwal && this.leftFilterData.periodeAkhir != null && this.leftFilterData.periodeAwal != null) {
        
          let timerInterval
          Swal.fire({
            title: 'Peringatan!',
            html: 'Periode Awal Tidak boleh Melebihi Periode Akhir',
            timer: 4000,
            timerProgressBar: true,
            showCloseButton: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
            },

          }).then((result) => {
            /* Read more about handling dismissals below */

            if (result.dismiss === Swal.DismissReason.timer) {
              this.leftFilterData.periodeAkhir = null

            }
            this.leftFilterData.periodeAkhir = null
          })
        }

      }


    },


    'leftFilterData.periodeAwal': {
      handler() {
        if (this.leftFilterData.periodeAwal > this.leftFilterData.periodeAkhir && this.leftFilterData.periodeAwal != null && this.leftFilterData.periodeAkhir != null) {

          let timerInterval
          Swal.fire({
            title: 'Peringatan!',
            html: 'Periode Awal Tidak boleh Melebihi Periode Akhir',
            timer: 4000,
            timerProgressBar: true,
            showCloseButton: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
            },

          }).then((result) => {
            /* Read more about handling dismissals below */

            if (result.dismiss === Swal.DismissReason.timer) {
              this.leftFilterData.periodeAwal = null
              this.leftFilterData.periodeAkhir = null

            }
            this.leftFilterData.periodeAwal = null
            this.leftFilterData.periodeAkhir = null
          })
        }

      }


    },
    'leftFilterData.rentangHargaAwal': {
      handler() {
        if (this.leftFilterData.rentangHargaAwal >= this.leftFilterData.rentangHargaAkhir && this.leftFilterData.rentangHargaAwal != null && this.leftFilterData.rentangHargaAkhir != null) {

          let timerInterval
          Swal.fire({
            title: 'Peringatan!',
            html: 'Harga Minimal Tidak boleh Melebihi Harga Maximal',
            timer: 4000,
            timerProgressBar: true,
            showCloseButton: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
            },

          }).then((result) => {
            /* Read more about handling dismissals below */

            if (result.dismiss === Swal.DismissReason.timer) {
              this.leftFilterData.rentangHargaAwal = null
              this.leftFilterData.rentangHargaAkhir = null

            }
            this.leftFilterData.rentangHargaAwal = null
            this.leftFilterData.rentangHargaAkhir = null
          })
        }

      }


    },

   

    async dynamicSearchText(...args) {
      await this.debouncedWatch(...args);

    },
    'checkedNames': {
      async handler(test) {
       
        if (test.length >= 2) {

          if (test.length >= 3) {
            this.costumeFilterTableLimit = false
          }
          let targetIndex

          let added = test.filter(
            (val) => !this.previousSelectedStructures.includes(val)
          );
          let removed = this.previousSelectedStructures.filter(
            (val) => !test.includes(val)
          );


          if (added.length != 0) {

            for (let index = 0; index < this.costumeFields.length; index++) {


              if (added == this.costumeFields[index].value) {

                targetIndex = {
                  'do': "tambah",
                  'index': index
                }

              }

            }

          }
          if (removed.length != 0) {
            for (let index = 0; index < this.fields.length; index++) {

              if (removed == this.fields[index].value) {

                targetIndex = {
                  'do': "hapus",
                  'index': index
                }
              }

            }

          }
          
          if (!this.costumeFilterTableLimit) {
            await this.tableCostume(targetIndex)
            this.previousSelectedStructures = test
          }




        

        }
        else if (test.length <= 2) {
          let timerInterval
          Swal.fire({
            title: 'Peringatan!',
            html: 'Silahkan Pilih Setidaknya 2 Opsi',
            timer: 4000,
            timerProgressBar: true,
            showCloseButton: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
            },

          }).then((result) => {
            /* Read more about handling dismissals below */

            if (result.dismiss === Swal.DismissReason.timer) {
              if (test.length <= 1) {
                this.costumeFilterTableLimit = true
                this.checkedNames = this.previousSelectedStructures
              }
              else {
                this.costumeFilterTableLimit = false
              }

            }
            if (test.length <= 1) {
              this.costumeFilterTableLimit = true
              this.checkedNames = this.previousSelectedStructures
            }
            else {
              this.costumeFilterTableLimit = false
            }

          })
        }
      }

    },






  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <loading v-model="isLoading" :active="isLoading" :can-cancel="true"></loading>
    <div class="row ">
      <div class="col-sm-12 col-md-12">
      <div class= "row">
        <div class ="col-2">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
          </label>
            <b-form-select v-model="perPage" :options="pageOptions" size="sm" class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        </div>
        <div class = "col-7">
          <b-dropdown class="ml-4" text="Edit Tabel">
            <b-dropdown-form>
              <b-form-checkbox-group :options="costumeFields" :value="costumeFields" v-model="checkedNames"
                style="padding-left: 6px;">
              </b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <div class= "col-3 text-end">
          <label class="d-inline-flex align-items-center fw-bold font-size-16">
            Pencarian:
            <b-form-input v-model="dynamicSearchText" v-mask="'###################'" type="search" placeholder="Order Id"
              class="form-control form-control-sm ms-1" style="width: 60%;"></b-form-input>
          </label>
        </div>

       </div>
     
      
    </div>
      <!-- Search -->
     
      <!-- End search -->
      <div class="row mb-4">
        <div class="col-xl-2 mt-0">
          <div class="card h-100">
            <div class="card-body pt-0">
              <div class="text-center">

                <div class="clearfix"></div>
                <div>

                </div>
                <h5 class="mt-3 mb-1"></h5>
                <p class="text-muted"></p>
              </div>

              <div class="text-muted">
                <div class="table-responsive mt-4 mb-0">
                  <div class="custom-accordion">
                    <div class="card">

                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-shippinginfo-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-calender text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h4 class="font-weight-bold font-size-12">Periode Tagihan</h4>
                              <p class="text-muted text-truncate mb-0">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-shippinginfo-collapse" v-model="compoAcordion.acordion1">

                        <div class="p-2 border-top">
                          <div class="card border-4">

                            <div class="row-12">
                              <date-picker v-model="leftFilterData.periodeAwal" type="month" placeholder="Periode Awal"
                                :default-value="new Date()" :disabled-date="disabledAfterToday"
                                format="MMMM-YYYY"></date-picker>
                            </div>

                            <div class="row-12 mt-2">
                              <date-picker v-model="leftFilterData.periodeAkhir" type="month" placeholder="Periode Akhir"
                                format="MMMM-YYYY" :default-value="new Date()"
                                :disabled-date="disabledAfterToday"></date-picker>
                            </div>
                            <div class="row-12 mt-2">
                              <date-picker v-model="leftFilterData.tanggalTagihan" type="date"
                                placeholder="Tanggal Tagihan" format="DD-MM-YYYY" :default-value="new Date()"
                                :disabled-date="disabledAfterToday"></date-picker>
                            </div>
                            <div class="row-12 mt-2">
                              <date-picker v-model="leftFilterData.tanggalTransaksi" type="date"
                                placeholder="Tanggal  Transaksi" format="DD-MM-YYYY" :default-value="new Date()"
                                :disabled-date="disabledAfterToday"></date-picker>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-billinginfo-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="bx bx-map-pin text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13">Client Grup</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-22"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-billinginfo-collapse" v-model="compoAcordion.acordion2">

                        <div class="pr-2 border-top-3 mt-3">
                          <div class="card border-4 ">

                            <b-form-checkbox-group v-model="leftFilterData.clientGrup" :options="checkBoxClientGrup"
                              name="clientGrupList" size="sm" style="font-size: 11px;"></b-form-checkbox-group>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-iseng-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-bill text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13 mt-2">Status Invoice</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-iseng-collapse" v-model="compoAcordion.acordion3">

                        <div class="pr-2 border-top-2 mt-3">
                          <div class="card border-3 ">

                            <b-form-checkbox-group v-model="leftFilterData.statusInvocies" :options="statusInvoiceCekBox"
                              name="statusInvo" size="md" style="font-size: 11px;"> &nbsp;</b-form-checkbox-group>
                          </div>

                        </div>
                      </b-collapse>
                    </div>
                    <div class="card">
                      <a href="javascript: void(0);" class="collapsed text-dark" data-toggle="collapse"
                        v-b-toggle.checkout-rangePrice-collapse>
                        <div class="p-0">
                          <div class="media align-items-center">
                            <div class="me-3">
                              <i class="uil uil-pricetag-alt text-primary h2"></i>
                            </div>
                            <div class="media-body overflow-hidden">
                              <h2 class="font-weight-bold font-size-13 mt-2">Rentang Harga</h2>
                              <p class="text-muted text-truncate mb-2">

                              </p>

                            </div>
                            <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </div>
                        </div>
                      </a>

                      <b-collapse id="checkout-rangePrice-collapse" v-model="compoAcordion.acordion4">

                        <div class="pr-2 border-top-3 mt-3">
                          <div class="card border-4 ">

                            <div class="input-group">
                              <div class="input-group-text">Rp</div>
                              <input type="text" class="form-control" style=" padding-left: 2px;"
                                id="specificSizeInputGroupUsername" v-model="leftFilterData.rentangHargaAwal"
                                v-mask="'#########'" placeholder="Harga Minimal" />
                            </div>

                            <div class="input-group pt-3">
                              <div class="input-group-text">Rp</div>
                              <input type="text" class="form-control" style=" padding-left: 2px;" v-mask="'#########'"
                                id="specificSizeInputGroupUsername" v-model="leftFilterData.rentangHargaAkhir"
                                placeholder="Harga Maksimal" @blur="rentangHarga" />
                            </div>
                          </div>

                        </div>
                      </b-collapse>
                    </div>
                    <div>
                      <b-form-group>
                        <b-button pill variant="success" style="padding-right: 2.75rem;padding-left: 2.75rem;"
                          @click="setAdvFilter">Terapkan</b-button>
                        <p></p>
                        <b-button pill variant="warning" style="padding-right: 2.313rem;padding-left: 2.313rem;"
                          @click="resetAdvFilter" data-toggle="collapse multi">Reset Filter</b-button>
                      </b-form-group>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="col-xl-10">
          <div class="card mb-0">
            <div class="table-responsive mb-0" href="#">
              <b-table ref="table"  table-class="table table-centered datatable table-card-list" 
                thead-tr-class="bg-secondary" tbody-tr-class="custom-border" :items="invoicelist" :fields="fields"
                responsive="sm" :per-page="perPage" :current-page="currentPage" @row-clicked="rowClicked" :sticky-header="stickyHeader" >

                <template v-slot:cell(status)="data">
                  <div class="badge rounded-pill bg-soft-success font-size-12 font-weight-bold" :class="{
                    'bg-soft-warning': data.item.status === 'pending',
                    'bg-soft-danger': data.item.status === 'cancel'
                  }">
                    {{ data.item.status }}
                  </div>
                </template>
                <template v-slot:cell(NamaPelanggan)="data">
                  <a href="#" class="font-weight-bold font-18">{{ data.item.nama }}</a>
                </template>



              </b-table>
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :limit=3
                    @page-click="checkPagination" :hide-goto-end-buttons=true></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.atom-spinner,
.atom-spinner * {
  box-sizing: border-box;
}

.atom-spinner {
  height: 60px;
  width: 60px;
  overflow: hidden;
}

.atom-spinner .spinner-inner {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.atom-spinner .spinner-circle {
  display: block;
  position: absolute;
  color: #ff1d5e;
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.atom-spinner .spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-duration: 1s;
  border-left-width: calc(60px / 25);
  border-top-width: calc(60px / 25);
  border-left-color: #ff1d5e;
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: transparent;
}

.atom-spinner .spinner-line:nth-child(1) {
  animation: atom-spinner-animation-1 1s linear infinite;
  transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}

.atom-spinner .spinner-line:nth-child(2) {
  animation: atom-spinner-animation-2 1s linear infinite;
  transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}

.atom-spinner .spinner-line:nth-child(3) {
  animation: atom-spinner-animation-3 1s linear infinite;
  transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@keyframes atom-spinner-animation-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}

.custom-border>td {
  border-bottom: 2px solid #dddddd;
  border-top: 1px solid #dddddd;
}

p.outset {
  border-style: outset;
}

</style>
